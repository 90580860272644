import React from 'react';
import { Helmet } from 'react-helmet-async';

const AboutUsMeta = () => {
  return (
    <Helmet>
      <title>About TribeLink | Student-Run Service Marketplace</title>
      <meta name="description" content="Learn about TribeLink, a student-run startup from Manipal Institute of Technology revolutionizing how people access everyday services." />
      <meta name="keywords" content="TribeLink, about us, student startup, Manipal, service marketplace, MIT" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content="About TribeLink | Our Story and Team" />
      <meta property="og:description" content="Born in the vibrant campus of Manipal Institute of Technology, TribeLink is a student-run startup revolutionizing service access." />
      <meta property="og:image" content="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&auto=format&fit=crop&w=870&q=80" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:title" content="About TribeLink | Student Entrepreneurs" />
      <meta name="twitter:description" content="Meet the team behind TribeLink, a student-run service marketplace from MIT Manipal." />
      <meta name="twitter:image" content="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&auto=format&fit=crop&w=870&q=80" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={window.location.origin + "/about"} />
    </Helmet>
  );
};

export default AboutUsMeta;
