import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { data } from '../data';
import ProviderModal from './ProviderModal';
import { FiChevronRight, FiShield, FiStar, FiClock, FiMapPin } from 'react-icons/fi';
import { HiOutlineWifi } from 'react-icons/hi2';
import { TbSortAscending } from 'react-icons/tb';
import ServicePageMeta from '../metatags/servicemeta';

const ServicePage = () => {
  const { categoryId } = useParams();
  const category = data.find(cat => cat.id === categoryId);

  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('price-low');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 5000]);

  // Set the first subcategory as default when component mounts
  useEffect(() => {
    if (category && category.subcategories.length > 0) {
      setSelectedSubcategory(category.subcategories[0]);
    }
  }, [category]);

  const handleSubcategoryClick = (sub) => {
    setSelectedSubcategory(sub);
    setSelectedService(null);
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  const sortedServices = selectedSubcategory?.services.slice().sort((a, b) => {
    if (sortBy === 'price-low') return a.lowestPrice - b.lowestPrice;
    if (sortBy === 'price-high') return b.lowestPrice - a.lowestPrice;
    if (sortBy === 'popularity') return b.popularity - a.popularity;
    return 0;
  }).filter(service => 
    service.lowestPrice >= priceRange[0] && service.lowestPrice <= priceRange[1]
  );

  if (!category) return <div className="p-8 text-center">Category not found</div>;

  return (
    <div className="bg-white min-h-screen">
       <ServicePageMeta 
        category={category} 
        selectedSubcategory={selectedSubcategory} 
        selectedService={selectedService}
      />
      {/* Breadcrumb Navigation */}
      <div className="bg-gray-50 py-3 px-6 border-b">
        <div className="max-w-7xl mx-auto flex items-center text-sm text-gray-600">
          <Link to="/" className="hover:text-green-600">Home</Link>
          <FiChevronRight className="mx-2" />
          <Link to="/service" className="hover:text-green-600">Services</Link>
          <FiChevronRight className="mx-2" />
          <span className="text-green-700 font-medium">{category.name}</span>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 py-6">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2 flex items-center">
              <category.icon className="mr-3 text-green-600 text-4xl" />
              {category.name} Services
            </h1>
            <p className="text-gray-600 max-w-2xl">
              Find the best {category.name.toLowerCase()} services from verified professionals. All services come with quality guarantee and competitive pricing.
            </p>
          </div>
          <div className="mt-4 md:mt-0 flex items-center">
            <div className="flex items-center bg-green-50 px-3 py-1 rounded-full text-green-700 mr-3">
              <FiStar className="mr-1 text-yellow-500" />
              <span>4.8/5 Rating</span>
            </div>
            <div className="flex items-center bg-blue-50 px-3 py-1 rounded-full text-blue-700">
              <FiClock className="mr-1" />
              <span>Fast Service</span>
            </div>
          </div>
        </div>

        {/* Main Service Area */}
        <div className="flex flex-col lg:flex-row">
          {/* Subcategories Sidebar */}
          <div className="w-full lg:w-1/4 mb-6 lg:mb-0 lg:pr-6">
            <div className="bg-white rounded-lg shadow-sm border p-4 sticky top-4">
              <h2 className="font-semibold text-lg mb-4 pb-2 border-b">Categories</h2>
              <div className="space-y-2">
                {category.subcategories.map(sub => (
                  <div
                    key={sub.id}
                    onClick={() => handleSubcategoryClick(sub)}
                    className={`flex items-center p-3 rounded-lg transition cursor-pointer 
                      ${selectedSubcategory?.id === sub.id ? 'bg-green-50 border-l-4 border-green-500' : 'hover:bg-gray-50'}`}
                  >
                    <sub.icon className="text-xl text-gray-700" />
                    <span className="ml-3 font-medium">{sub.name}</span>
                  </div>
                ))}
              </div>

              {/* Price Filter */}
              <div className="mt-6 pt-4 border-t">
                <h3 className="font-semibold mb-3">Price Range</h3>
                <div className="px-2">
                  <input
                    type="range"
                    min="0"
                    max="5000"
                    step="100"
                    value={priceRange[1]}
                    onChange={(e) => setPriceRange([priceRange[0], parseInt(e.target.value)])}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <div className="flex justify-between mt-2 text-sm text-gray-600">
                    <span>₹{priceRange[0]}</span>
                    <span>₹{priceRange[1]}</span>
                  </div>
                </div>
              </div>

              {/* Location Badge */}
              <div className="mt-6 pt-4 border-t">
                <div className="flex items-center bg-gray-50 p-3 rounded-lg">
                  <FiMapPin className="text-red-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">Service Area</p>
                    <p className="text-xs text-gray-600">Pan India Coverage</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Services Content */}
          <div className="flex-1">
            {selectedSubcategory && (
              <>
                {/* Subcategory Header */}
                <div className="bg-white p-4 rounded-lg shadow-sm border mb-4">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <selectedSubcategory.icon className="text-2xl text-green-600 mr-3" />
                      <h2 className="text-xl font-semibold">{selectedSubcategory.name}</h2>
                    </div>
                    
                    {/* Sort and Filter Controls */}
                    <div className="flex items-center space-x-2">
                      <div className="relative">
                        <button 
                          onClick={() => setIsFilterOpen(!isFilterOpen)}
                          className="flex items-center px-3 py-2 border rounded-md text-sm hover:bg-gray-50"
                        >
                          <TbSortAscending className="mr-1" />
                          Sort
                        </button>
                        {isFilterOpen && (
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 border">
                            <div className="py-1">
                              <button 
                                onClick={() => {setSortBy('price-low'); setIsFilterOpen(false);}}
                                className={`block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 ${sortBy === 'price-low' ? 'bg-green-50 text-green-700' : ''}`}
                              >
                                Price: Low to High
                              </button>
                              <button 
                                onClick={() => {setSortBy('price-high'); setIsFilterOpen(false);}}
                                className={`block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 ${sortBy === 'price-high' ? 'bg-green-50 text-green-700' : ''}`}
                              >
                                Price: High to Low
                              </button>
                              <button 
                                onClick={() => {setSortBy('popularity'); setIsFilterOpen(false);}}
                                className={`block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 ${sortBy === 'popularity' ? 'bg-green-50 text-green-700' : ''}`}
                              >
                                Popularity
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                </div>

                {/* Services Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {sortedServices && sortedServices.length > 0 ? (
                    sortedServices.map(service => (
                      <div
                        key={service.id}
                        onClick={() => handleServiceClick(service)}
                        className="bg-white rounded-lg overflow-hidden border transition-all hover:shadow-md cursor-pointer group"
                      >
                        <div className="h-40 bg-gray-100 relative overflow-hidden">
                          {/* Replace with actual service image */}
                          <div className="absolute inset-0 bg-gradient-to-br from-green-400/20 to-blue-500/20 flex items-center justify-center">
                            <service.icon className="text-6xl text-gray-700" />
                          </div>
                          {service.discount && (
                            <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 text-xs font-bold">
                              {service.discount}% OFF
                            </div>
                          )}
                        </div>
                        <div className="p-4">
                          <h3 className="font-semibold text-lg mb-2 group-hover:text-green-600">{service.name}</h3>
                          <div className="flex items-center text-sm text-gray-500 mb-3">
                            <FiStar className="text-yellow-400 mr-1" />
                            <span className="mr-2">4.8</span>
                            <span className="text-gray-400">|</span>
                            <span className="ml-2">50+ bookings</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <div>
                              <p className="text-gray-500 text-sm">Starting from</p>
                              <p className="text-green-700 font-bold text-lg">₹{service.lowestPrice}</p>
                            </div>
                            <button className="px-4 py-2 bg-green-600 text-white rounded-md text-sm font-medium hover:bg-green-700 transition-colors">
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-span-3 py-12 text-center">
                      <p className="text-gray-500">No services found matching your criteria.</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Trust Badges */}
      <div className="bg-gray-50 py-8 mt-12 border-t">
        <div className="max-w-7xl mx-auto px-4">
          <h3 className="text-xl font-semibold text-center mb-6">Why Choose Our Services?</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="bg-white p-4 rounded-lg shadow-sm flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-3">
                <FiStar className="text-green-600 text-xl" />
              </div>
              <h4 className="font-medium mb-1">Quality Assured</h4>
              <p className="text-sm text-gray-600">All service providers are verified and quality checked</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-3">
                <FiClock className="text-blue-600 text-xl" />
              </div>
              <h4 className="font-medium mb-1">On-time Service</h4>
              <p className="text-sm text-gray-600">Punctual professionals who respect your time</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mb-3">
                <FiMapPin className="text-purple-600 text-xl" />
              </div>
              <h4 className="font-medium mb-1">Pan India Coverage</h4>
              <p className="text-sm text-gray-600">Services available across all major cities</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm flex flex-col items-center text-center">
              <div className="w-12 h-12 bg-amber-100 rounded-full flex items-center justify-center mb-3">
                <FiShield className="text-amber-600 text-xl" />
              </div>
              <h4 className="font-medium mb-1">Secure Booking</h4>
              <p className="text-sm text-gray-600">ONDC verified with secure payment options</p>
            </div>
          </div>
        </div>
      </div>

      {/* Provider Modal */}
      <ProviderModal 
        selectedService={selectedService} 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
      />
    </div>
  );
};

export default ServicePage;
