import React from 'react';
import { motion } from 'framer-motion';
import { FiUsers, FiTarget, FiTrendingUp, FiAward, FiMapPin, FiMail, FiPhone } from 'react-icons/fi';
import { HiOutlineAcademicCap, HiOutlineLightBulb } from 'react-icons/hi';
import AboutUsMeta from '../metatags/aboutustags';

const AboutUs = () => {
  const teamMembers = [
    {
      name: "Aryan Sharma",
      role: "Co-Founder & CEO",
      image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      bio: "Computer Science student with a passion for entrepreneurship and technology."
    },
    {
      name: "Priya Patel",
      role: "Co-Founder & COO",
      image: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80",
      bio: "Business Administration student specializing in operations and customer experience."
    },
    {
      name: "Vikram Singh",
      role: "CTO",
      image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      bio: "Engineering student with expertise in full-stack development and system architecture."
    },
    {
      name: "Neha Reddy",
      role: "Marketing Lead",
      image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=461&q=80",
      bio: "Digital Marketing enthusiast with a flair for creative campaigns and growth strategies."
    }
  ];

  return (
    <div className="bg-white">
      <AboutUsMeta />
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-green-600 to-teal-500 text-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <motion.h1 
                className="text-4xl md:text-5xl font-bold mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Our Story
              </motion.h1>
              <motion.p 
                className="text-xl leading-relaxed"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Born in the vibrant campus of Manipal Institute of Technology, TribeLink is a student-run startup revolutionizing how people access everyday services.
              </motion.p>
            </div>
            <motion.div 
              className="md:w-1/2 flex justify-center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <img 
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" 
                alt="TribeLink Team" 
                className="rounded-lg shadow-xl max-w-full h-auto"
              />
            </motion.div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Get In Touch</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Have questions or want to learn more about TribeLink? We'd love to hear from you!
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2 bg-green-600 text-white p-8">
                <h3 className="text-2xl font-bold mb-6">Contact Information</h3>
                
                <div className="space-y-4">
                  <div className="flex items-start">
                    <FiMapPin className="text-xl mr-4 mt-1" />
                    <div>
                      <p className="font-medium">Address</p>
                      <p>Innovation Center, MIT Campus</p>
                      <p>Manipal, Karnataka 576104</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    <FiMail className="text-xl mr-4" />
                    <div>
                      <p className="font-medium">Email</p>
                      <p>hello@tribelink.co.in</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    <FiPhone className="text-xl mr-4" />
                    <div>
                      <p className="font-medium">Phone</p>
                      <p>+91 9876543210</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="md:w-1/2 p-8">
                <h3 className="text-2xl font-bold text-gray-800 mb-6">Send us a message</h3>
                <form>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                    <div>
                      <label className="block text-gray-700 mb-2">Name</label>
                      <input type="text" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500" />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-2">Email</label>
                      <input type="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500" />
                    </div>
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-700 mb-2">Message</label>
                    <textarea rows="4" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"></textarea>
                  </div>
                  <button type="submit" className="bg-green-600 text-white py-2 px-6 rounded-lg hover:bg-green-700 transition-colors">Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
