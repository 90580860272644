// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import Navbar from './components/Navbar';
import Home from './components/Home';
import ServicePage from './components/ServicePage';
import Cart from './components/Cart';
import { CartProvider } from './contexts/cartContext';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Search from './components/ServicePage';
import ServiceCategoriesPage from './components/servicecategorypg';
import AboutUs from './components/Aboutus';
import AccountPage from './components/accountpg';
import SearchONDC from './components/SearchPage';

const App = () => {
  return (
    <HelmetProvider> {/* Wrap your entire app with HelmetProvider */}
      <Router>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        <CartProvider>
          <div className="flex flex-col min-h-screen">
            <Navbar />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services/:categoryId" element={<ServicePage />} />
                <Route path="/service" element={<ServiceCategoriesPage/>} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/search" element={<SearchONDC />}/>
                <Route path="/about" element={<AboutUs />}/>
                <Route path="/account" element={<AccountPage />}/>
              </Routes>
            </main>
            <Footer />
          </div>
        </CartProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
