import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner, faShoppingCart, faFilter, faStar } from "@fortawesome/free-solid-svg-icons";
import { useSearchData } from "./searchdata";

function SearchONDC() {
  const {
    messageId,
    results,
    init,
    loading,
    inputValue,
    categories,
    selectedCategory,
    selectedItem,
    showModal,
    setInputValue,
    setSelectedCategory,
    handleSubmit,
    handleItemClick,
    closeModal,
    filteredResults
  } = useSearchData();

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-green-50 mt-16">
      <div className="container mx-auto px-4 py-8">
        {/* Header */}


        {/* Hero Section */}
        <div className="bg-gradient-to-r from-green-500 to-green-400 rounded-2xl shadow-xl mb-10 overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="p-8 md:p-12 md:w-3/5">
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                Find the perfect service for your needs
              </h2>
              <p className="text-green-50 mb-8 text-lg">
                Browse through thousands of professional services and book instantly
              </p>
              
              <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-3">
                <div className="relative flex-grow">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="w-full rounded-lg border-0 p-4 pl-12 shadow-md focus:ring-2 focus:ring-green-300 focus:outline-none"
                    placeholder="What service are you looking for?"
                  />
                  <FontAwesomeIcon 
                    icon={faSearch} 
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-green-500" 
                  />
                </div>
                <button
                  type="submit"
                  className="rounded-lg bg-white text-green-600 p-4 font-semibold shadow-md hover:bg-green-50 transition-colors flex items-center justify-center whitespace-nowrap"
                  disabled={!init && loading}
                >
                  {!init && loading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                      Searching...
                    </>
                  ) : (
                    "Search Services"
                  )}
                </button>
              </form>
            </div>
            <div className="hidden md:block md:w-2/5 bg-green-300">
              <div className="h-full bg-[url('https://images.unsplash.com/photo-1521737604893-d14cc237f11d')] bg-cover bg-center opacity-80"></div>
            </div>
          </div>
        </div>

        {/* Results Section */}
        {!init && (
          <div className="bg-white rounded-xl shadow-md p-6 mb-8">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  {loading ? "Searching..." : `${filteredResults.length} Services Found`}
                </h2>
                {!loading && results.length > 0 && (
                  <p className="text-gray-500">Browse and book the service you need</p>
                )}
              </div>
              
              {/* Filter Section */}
              {categories.length > 0 && (
                <div className="mt-4 md:mt-0">
                  <div className="flex items-center space-x-3">
                    <FontAwesomeIcon icon={faFilter} className="text-green-600" />
                    <select 
                      className="border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-green-300 focus:outline-none"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="">All Categories</option>
                      {categories.map((category, idx) => (
                        <option key={idx} value={category}>{category}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>

            {loading ? (
              <div className="flex justify-center items-center h-64">
                <FontAwesomeIcon icon={faSpinner} className="animate-spin text-4xl text-green-500" />
              </div>
            ) : (
              <>
                {filteredResults.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredResults.map((item, idx) => (
                      <div 
                        key={idx} 
                        className="bg-white rounded-xl border border-gray-100 overflow-hidden shadow-sm hover:shadow-md transition-all transform hover:-translate-y-1"
                        onClick={() => handleItemClick(item)}
                      >
                        <div className="h-48 overflow-hidden relative">
                          {item.descriptor?.images && item.descriptor.images[0] ? (
                            <img 
                              src={item.descriptor.images[0]} 
                              alt={item.descriptor?.name || "Service image"} 
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full bg-gradient-to-r from-green-100 to-green-200 flex items-center justify-center">
                              <span className="text-green-500 text-lg font-medium">No Image Available</span>
                            </div>
                          )}
                          {item.category_id && (
                            <div className="absolute top-3 left-3 bg-green-500 text-white text-xs px-2 py-1 rounded-full">
                              {item.category_id}
                            </div>
                          )}
                          {item.provider_info && (
                            <div className="absolute bottom-3 right-3 bg-white bg-opacity-90 text-xs px-2 py-1 rounded-full text-green-700">
                              {item.provider_info.name}
                            </div>
                          )}
                        </div>
                        <div className="p-5">
                          <div className="flex items-center mb-2">
                            <div className="flex text-yellow-400 mr-2">
                              {[...Array(5)].map((_, i) => (
                                <FontAwesomeIcon key={i} icon={faStar} className={i < 4 ? "text-yellow-400" : "text-gray-300"} />
                              ))}
                            </div>
                            <span className="text-sm text-gray-600">4.0 (24 reviews)</span>
                          </div>
                          <h3 className="text-xl font-semibold text-gray-800 mb-2">{item.descriptor?.name || "Unnamed Service"}</h3>
                          <p className="text-gray-600 mb-4 text-sm line-clamp-2">
                            {item.descriptor?.short_desc || item.descriptor?.long_desc || "No description available"}
                          </p>
                          
                          <div className="flex justify-between items-center">
                            {item.price ? (
                              <p className="text-xl font-bold text-green-600">
                                ₹{item.price.value || "N/A"}
                              </p>
                            ) : (
                              <p className="text-xl font-bold text-green-600">Price on request</p>
                            )}
                            <button className="bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white px-4 py-2 rounded-lg transition-colors shadow-sm hover:shadow-md">
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center py-16">
                    <div className="text-green-500 text-6xl mb-4">
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <h3 className="text-2xl font-semibold text-gray-700 mb-2">No services found</h3>
                    <p className="text-gray-500 mb-6">Try adjusting your search or browse our categories</p>
                    <button onClick={() => setInputValue("")} className="text-green-600 border border-green-500 px-6 py-2 rounded-lg hover:bg-green-50 transition-colors">
                      Clear Search
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* Initial State */}
        {init && (
          <div className="bg-white rounded-xl shadow-md p-8 text-center">
            <div className="max-w-lg mx-auto">
              <div className="text-green-500 text-6xl mb-6">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                Find and book professional services
              </h2>
              <p className="text-gray-600 mb-6">
                Enter a search term above to discover services available in your area.
                From home repairs to healthcare, find exactly what you need.
              </p>
              <div className="flex flex-wrap justify-center gap-3">
                {["Home Services", "Healthcare", "Education", "Beauty", "Food"].map((cat, idx) => (
                  <button 
                    key={idx}
                    onClick={() => setInputValue(cat)}
                    className="bg-green-50 text-green-700 px-4 py-2 rounded-full hover:bg-green-100 transition-colors"
                  >
                    {cat}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}


      {/* Item Detail Modal */}
      {showModal && selectedItem && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl max-w-4xl w-full max-h-90vh overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-2xl font-bold text-gray-800">{selectedItem.descriptor?.name}</h2>
                <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  {selectedItem.descriptor?.images && selectedItem.descriptor.images[0] ? (
                    <img 
                      src={selectedItem.descriptor.images[0]} 
                      alt={selectedItem.descriptor?.name} 
                      className="w-full h-64 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-full h-64 bg-gradient-to-r from-green-100 to-green-200 flex items-center justify-center rounded-lg">
                      <span className="text-green-500 text-lg font-medium">No Image Available</span>
                    </div>
                  )}
                </div>
                
                <div>
                  <div className="mb-4">
                    <h3 className="text-lg font-semibold text-gray-700 mb-2">Description</h3>
                    <p className="text-gray-600">
                      {selectedItem.descriptor?.long_desc || selectedItem.descriptor?.short_desc || "No description available"}
                    </p>
                  </div>
                  
                  {selectedItem.price && (
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">Price</h3>
                      <p className="text-2xl font-bold text-green-600">₹{selectedItem.price.value || "N/A"}</p>
                    </div>
                  )}
                  
                  {selectedItem.provider_info && (
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">Provider</h3>
                      <p className="text-gray-600">{selectedItem.provider_info.name}</p>
                      <p className="text-gray-600">{selectedItem.provider_info.description}</p>
                    </div>
                  )}
                  
                  {selectedItem.provider_info?.fulfillments && selectedItem.provider_info.fulfillments.length > 0 && (
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">Delivery Options</h3>
                      <ul className="list-disc pl-5">
                        {selectedItem.provider_info.fulfillments.map((fulfillment, idx) => (
                          <li key={idx} className="text-gray-600">
                            {fulfillment.type}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  
                  <div className="mt-6">
                    <button className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white px-6 py-3 rounded-lg transition-colors shadow-md hover:shadow-lg">
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchONDC;
