import React, { useContext, useState } from 'react';
import { CartContext } from '../contexts/cartContext';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FiShoppingCart, 
  FiTrash2, 
  FiMinus, 
  FiPlus, 
  FiArrowRight, 
  FiChevronLeft,
  FiCreditCard,
  FiCalendar,
  FiClock,
  FiMapPin,
  FiCheckCircle,
  FiShield
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { IoTimeOutline } from 'react-icons/io5';
import { HiOutlineCash } from 'react-icons/hi';

const Cart = () => {
  const { cartItems, removeFromCart, clearCart, updateQuantity } = useContext(CartContext);
  const [couponCode, setCouponCode] = useState('');
  const [couponApplied, setCouponApplied] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  
  // Calculate subtotal - safely access price properties
  const subtotal = cartItems.reduce(
    (total, item) => total + (item.price?.value || item.price || 0) * (item.quantity || 1),
    0
  );
  
  // Apply discount if coupon is applied
  const discount = couponApplied ? subtotal * 0.1 : 0;
  
  // Service fee
  const serviceFee = subtotal > 0 ? 49 : 0;
  
  // Calculate total
  const total = subtotal - discount + serviceFee;

  const handleCheckout = () => {
    if (!selectedDate || !selectedTime) {
      alert('Please select a preferred date and time for your service');
      return;
    }
    
    alert(`Order placed successfully! Your service is scheduled for ${selectedDate} at ${selectedTime}`);
    clearCart();
  };

  const handleApplyCoupon = () => {
    if (couponCode.toLowerCase() === 'welcome10') {
      setCouponApplied(true);
      alert('Coupon applied successfully! 10% discount added.');
    } else {
      alert('Invalid coupon code');
    }
  };

  // Generate available dates (next 7 days)
  const getAvailableDates = () => {
    const dates = [];
    const today = new Date();
    
    for (let i = 1; i <= 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      
      const formattedDate = date.toLocaleDateString('en-US', { 
        weekday: 'short', 
        month: 'short', 
        day: 'numeric' 
      });
      
      dates.push(formattedDate);
    }
    
    return dates;
  };

  // Generate time slots
  const getTimeSlots = () => {
    return [
      '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', 
      '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM'
    ];
  };

  // Get item name safely
  const getItemName = (item) => {
    if (!item) return 'Service';
    return item?.descriptor?.name || item?.name || item?.service?.name || 'Service';
};



  // Get item price safely
  const getItemPrice = (item) => {
    if (item.price?.value) return item.price.value;
    if (typeof item.price === 'number') return item.price;
    return 0;
  };

  // Get original price safely
  const getOriginalPrice = (item) => {
    if (item.price?.original_value) return item.price.original_value;
    return null;
  };

  // Get provider name safely
  const getProviderName = (item) => {
    if (item.provider?.name) return item.provider.name;
    if (item.providerName) return item.providerName;
    return 'Professional Service';
  };

  return (
    <div className="bg-gray-50 min-h-screen py-8">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center mb-8">
          <Link to="/services" className="flex items-center text-gray-600 hover:text-green-600 transition-colors">
            <FiChevronLeft className="mr-1" />
            <span>Continue Shopping</span>
          </Link>
          <h1 className="text-2xl font-bold text-gray-800 ml-auto">Your Cart</h1>
          <div className="flex items-center ml-auto bg-white px-3 py-1 rounded-full shadow-sm">
            <FiShoppingCart className="text-green-600 mr-2" />
            <span className="font-medium">{cartItems.length} items</span>
          </div>
        </div>

        {cartItems.length === 0 ? (
          <div className="bg-white rounded-xl shadow-sm p-8 text-center">
            <div className="flex justify-center mb-4">
              <FiShoppingCart className="text-gray-300 text-6xl" />
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Your cart is empty</h2>
            <p className="text-gray-600 mb-6">Looks like you haven't added any services to your cart yet.</p>
            <Link 
              to="/services" 
              className="inline-flex items-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
            >
              Browse Services
              <FiArrowRight className="ml-2" />
            </Link>
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Cart Items */}
            <div className="lg:w-2/3">
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-4 border-b">
                  <h2 className="font-semibold text-lg text-gray-800">Service Details</h2>
                </div>
                
                <AnimatePresence>
                  {cartItems?.length > 0 && cartItems.map((item) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="border-b last:border-b-0"
                    >
                      <div className="p-4 flex flex-col sm:flex-row">
                        {/* Service Image/Icon */}
                        <div className="w-16 h-16 bg-gray-100 rounded-lg flex items-center justify-center mr-4 mb-3 sm:mb-0">
                          {item.icon ? (
                            <item.icon className="text-2xl text-gray-600" />
                          ) : (
                            <FiCheckCircle className="text-2xl text-gray-600" />
                          )}
                        </div>
                        
                        {/* Service Details */}
                        <div className="flex-1">
                        <h3 className="font-medium text-lg text-gray-800">{getItemName(item)}</h3>

                          
                          <div className="flex flex-wrap gap-x-4 gap-y-1 mt-1 mb-3">
                            {item.provider && (
                              <span className="text-sm text-gray-600">
                                Provider: {item.provider.name || 'Professional Service'}
                              </span>
                            )}
                            
                            <span className="text-sm text-gray-600 flex items-center">
                              <IoTimeOutline className="mr-1" />
                              {item.time || '60 min'}
                            </span>
                          </div>
                          
                          {/* Price and Quantity Controls */}
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <span className="text-gray-800 font-medium">₹{item.price.value}</span>
                              {item.price.original_value && item.price.original_value > item.price.value && (
                                <span className="text-sm text-gray-500 line-through ml-2">
                                  ₹{item.price.original_value}
                                </span>
                              )}
                            </div>
                            
                            <div className="flex items-center">
                              <button
                                onClick={() => updateQuantity(item.id, Math.max(1, item.quantity - 1))}
                                className="w-8 h-8 flex items-center justify-center rounded-full border text-gray-600 hover:bg-gray-100"
                              >
                                <FiMinus size={14} />
                              </button>
                              <span className="mx-3 w-6 text-center">{item.quantity}</span>
                              <button
                                onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                className="w-8 h-8 flex items-center justify-center rounded-full border text-gray-600 hover:bg-gray-100"
                              >
                                <FiPlus size={14} />
                              </button>
                              
                              <button
                                onClick={() => removeFromCart(item.id)}
                                className="ml-4 text-red-500 hover:text-red-700 transition-colors"
                              >
                                <FiTrash2 />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
                
                {/* Clear Cart Button */}
                <div className="p-4 border-t bg-gray-50">
                  <button
                    onClick={clearCart}
                    className="text-sm text-red-500 hover:text-red-700 transition-colors flex items-center"
                  >
                    <FiTrash2 className="mr-1" />
                    Clear Cart
                  </button>
                </div>
              </div>
              
              {/* Schedule Section */}
              <div className="bg-white rounded-xl shadow-sm mt-6 overflow-hidden">
                <div className="p-4 border-b">
                  <h2 className="font-semibold text-lg text-gray-800 flex items-center">
                    <FiCalendar className="mr-2 text-green-600" />
                    Schedule Your Service
                  </h2>
                </div>
                
                <div className="p-4">
                  <h3 className="font-medium text-gray-800 mb-3">Select Date</h3>
                  <div className="flex overflow-x-auto pb-2 space-x-2">
                    {getAvailableDates().map((date) => (
                      <button
                        key={date}
                        onClick={() => setSelectedDate(date)}
                        className={`px-4 py-2 rounded-lg whitespace-nowrap ${
                          selectedDate === date 
                            ? 'bg-green-600 text-white' 
                            : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                        }`}
                      >
                        {date}
                      </button>
                    ))}
                  </div>
                  
                  <h3 className="font-medium text-gray-800 mt-6 mb-3">Select Time</h3>
                  <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-2">
                    {getTimeSlots().map((time) => (
                      <button
                        key={time}
                        onClick={() => setSelectedTime(time)}
                        className={`px-3 py-2 rounded-lg text-center ${
                          selectedTime === time 
                            ? 'bg-green-600 text-white' 
                            : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                        }`}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            
            {/* Order Summary */}
            <div className="lg:w-1/3">
              <div className="bg-white rounded-xl shadow-sm sticky top-4">
                <div className="p-4 border-b">
                  <h2 className="font-semibold text-lg text-gray-800">Order Summary</h2>
                </div>
                
                <div className="p-4">
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-600">Subtotal</span>
                      <span className="text-gray-800">₹{subtotal.toFixed(2)}</span>
                    </div>
                    
                    {couponApplied && (
                      <div className="flex justify-between text-green-600">
                        <span>Discount (10%)</span>
                        <span>-₹{discount.toFixed(2)}</span>
                      </div>
                    )}
                    
                    <div className="flex justify-between">
                      <span className="text-gray-600">Service Fee</span>
                      <span className="text-gray-800">₹{serviceFee.toFixed(2)}</span>
                    </div>
                    
                    <div className="border-t pt-3 mt-3">
                      <div className="flex justify-between font-bold">
                        <span>Total</span>
                        <span className="text-green-700">₹{total.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                  
                  {/* Coupon Code */}
                  <div className="mt-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Apply Coupon</label>
                    <div className="flex">
                      <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder="Enter coupon code"
                        className="flex-1 px-3 py-2 border rounded-l-lg focus:ring-green-500 focus:border-green-500"
                        disabled={couponApplied}
                      />
                      <button
                        onClick={handleApplyCoupon}
                        disabled={couponApplied}
                        className={`px-4 py-2 rounded-r-lg ${
                          couponApplied
                            ? 'bg-gray-300 text-gray-600'
                            : 'bg-green-600 text-white hover:bg-green-700'
                        }`}
                      >
                        Apply
                      </button>
                    </div>
                    {couponApplied && (
                      <p className="text-sm text-green-600 mt-1">Coupon applied successfully!</p>
                    )}
                    {!couponApplied && (
                      <p className="text-xs text-gray-500 mt-1">Try 'WELCOME10' for 10% off your first order</p>
                    )}
                  </div>
                  
                  {/* Payment Options */}
                  <div className="mt-6">
                    <h3 className="font-medium text-gray-800 mb-3">Payment Methods</h3>
                    <div className="space-y-2">
                      <div className="flex items-center p-3 border rounded-lg bg-gray-50">
                        <input
                          type="radio"
                          id="payment-card"
                          name="payment"
                          defaultChecked
                          className="h-4 w-4 text-green-600 focus:ring-green-500"
                        />
                        <label htmlFor="payment-card" className="ml-3 flex items-center text-gray-700">
                          <FiCreditCard className="mr-2" />
                          Pay with Card
                        </label>
                      </div>
                      <div className="flex items-center p-3 border rounded-lg">
                        <input
                          type="radio"
                          id="payment-cash"
                          name="payment"
                          className="h-4 w-4 text-green-600 focus:ring-green-500"
                        />
                        <label htmlFor="payment-cash" className="ml-3 flex items-center text-gray-700">
                          <HiOutlineCash className="mr-2" />
                          Pay on Service
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  {/* Checkout Button */}
                  <button
                    onClick={handleCheckout}
                    className={`w-full mt-6 py-3 px-4 rounded-lg font-medium flex items-center justify-center ${
                      selectedDate && selectedTime
                        ? 'bg-green-600 text-white hover:bg-green-700'
                        : 'bg-gray-300 text-gray-600 cursor-not-allowed'
                    }`}
                  >
                    {selectedDate && selectedTime ? 'Proceed to Checkout' : 'Select Date & Time'}
                    <FiArrowRight className="ml-2" />
                  </button>
                  
                  {/* Trust Badges */}
                  <div className="mt-6 pt-4 border-t">
                    <div className="flex flex-wrap justify-center gap-3">
                      <div className="flex items-center text-xs text-gray-600">
                        <FiClock className="mr-1 text-green-600" />
                        <span>Quick Service</span>
                      </div>
                      <div className="flex items-center text-xs text-gray-600">
                        <FiCheckCircle className="mr-1 text-green-600" />
                        <span>Verified Experts</span>
                      </div>
                      <div className="flex items-center text-xs text-gray-600">
                        <FiMapPin className="mr-1 text-green-600" />
                        <span>Service at Your Location</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
