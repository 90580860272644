import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { data } from '../data';
import { gsap } from 'gsap';
import { Typewriter } from 'react-simple-typewriter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, 
  faCheckCircle, 
  faUsers, 
  faTools, 
  faHeadset, 
  faStar, 
  faShieldAlt, 
  faClock,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import ProviderModal from './ProviderModal';
import HeroSection from './heroSection';
import MetaTags from '../metatags/hometags';

const Home = () => {
  const categoryRefs = useRef([]);
  const [serviceList, setServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const serviceNames = data.flatMap(category =>
    category.subcategories.flatMap(sub => sub.services.map(service => service.name))
  );

  useEffect(() => {
    const services = data.flatMap(category =>
      category.subcategories.flatMap(sub => sub.services)
    );
    setServiceList(services);

    // Enhanced animation with GSAP
    categoryRefs.current.forEach((ref) => {
      if (!ref) return;
      
      const tl = gsap.timeline({ paused: true });
      tl.to(ref, { 
        scale: 1.05, 
        boxShadow: "0 12px 30px rgba(0,0,0,0.15)", 
        duration: 0.3,
        ease: "power2.out"
      });

      ref.addEventListener('mouseenter', () => tl.play());
      ref.addEventListener('mouseleave', () => tl.reverse());
    });

    return () => {
      categoryRefs.current.forEach((ref) => {
        if (ref) {
          ref.removeEventListener('mouseenter', () => {});
          ref.removeEventListener('mouseleave', () => {});
        }
      });
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredServices = serviceList.filter(service =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  return (
    <div className="bg-gradient-to-b from-green-50 to-white min-h-screen">
      <HeroSection />
      <MetaTags />

      {/* Improved Typewriter Section */}
      <div className="mt-8 flex justify-center mb-4">
        <div className="text-slate-700">
          <p className="text-lg md:text-xl font-semibold text-center">
            <span className="text-slate-600">Discover services like </span>
            <span className="relative inline-block px-4 py-2 bg-gradient-to-r from-green-500 to-emerald-600 text-white font-bold rounded-md shadow-lg">
              <Typewriter
                words={serviceNames}
                loop={Infinity}
                cursor
                cursorStyle="_"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={3000}
              />
            </span>
          </p>
        </div>
      </div>

      {/* Enhanced Search Box */}
      <div className="relative flex justify-center items-center mt-6 mb-8">
        <div className={`relative w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 transition-all duration-300 ${isSearchFocused ? 'scale-105' : ''}`}>
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
              placeholder="What service are you looking for today?"
              className="w-full p-4 pl-12 rounded-full border-2 border-green-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent bg-white shadow-md text-lg transition-all duration-300"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-green-500 text-xl"
            />
          </div>
          {searchQuery && (
            <ul className="absolute left-0 right-0 bg-white border border-gray-200 rounded-lg shadow-lg mt-2 max-h-60 overflow-auto z-10">
              {filteredServices.length > 0 ? (
                filteredServices.map(service => (
                  <li
                    key={service.id}
                    className="p-3 hover:bg-green-50 cursor-pointer border-b border-gray-100 last:border-b-0 flex items-center"
                    onClick={() => handleServiceSelect(service)}
                  >
                    <span className="mr-2 text-green-500">{service.icon || '🔍'}</span>
                    <span>{service.name}</span>
                    <span className="ml-auto text-green-600 font-medium">₹{service.lowestPrice}</span>
                  </li>
                ))
              ) : (
                <li className="p-4 text-gray-500 text-center">No services found</li>
              )}
            </ul>
          )}
        </div>
      </div>

      {/* Trust Badges */}
      <div className="flex justify-center mb-12">
        <div className="flex flex-wrap justify-center gap-4 w-11/12 md:w-3/4">
          <div className="bg-white px-4 py-2 rounded-full shadow-sm flex items-center">
            <FontAwesomeIcon icon={faShieldAlt} className="text-green-600 mr-2" />
            <span className="text-sm font-medium">ONDC Verified</span>
          </div>
          <div className="bg-white px-4 py-2 rounded-full shadow-sm flex items-center">
            <FontAwesomeIcon icon={faStar} className="text-amber-500 mr-2" />
            <span className="text-sm font-medium">4.8/5 Rating</span>
          </div>
          <div className="bg-white px-4 py-2 rounded-full shadow-sm flex items-center">
            <FontAwesomeIcon icon={faClock} className="text-blue-600 mr-2" />
            <span className="text-sm font-medium">Fast Service</span>
          </div>
          <div className="bg-white px-4 py-2 rounded-full shadow-sm flex items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-red-500 mr-2" />
            <span className="text-sm font-medium">Pan India</span>
          </div>
        </div>
      </div>

      {/* Enhanced Features Section */}
      <div className="flex justify-center mt-8 mb-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-11/12 md:w-4/5">
          <div className="flex flex-col items-center p-6 rounded-xl bg-white shadow-md hover:shadow-xl transition-all duration-300 border-t-4 border-green-500">
            <div className="w-16 h-16 flex items-center justify-center bg-green-100 rounded-full mb-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-600 text-2xl" />
            </div>
            <h3 className="text-lg font-semibold text-green-700 mb-2">Quality Services</h3>
            <p className="text-gray-600 text-center text-sm">Verified professionals delivering exceptional service quality.</p>
          </div>
          <div className="flex flex-col items-center p-6 rounded-xl bg-white shadow-md hover:shadow-xl transition-all duration-300 border-t-4 border-blue-500">
            <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-4">
              <FontAwesomeIcon icon={faUsers} className="text-blue-600 text-2xl" />
            </div>
            <h3 className="text-lg font-semibold text-blue-700 mb-2">Community Support</h3>
            <p className="text-gray-600 text-center text-sm">Join thousands of satisfied customers in our community.</p>
          </div>
          <div className="flex flex-col items-center p-6 rounded-xl bg-white shadow-md hover:shadow-xl transition-all duration-300 border-t-4 border-amber-500">
            <div className="w-16 h-16 flex items-center justify-center bg-amber-100 rounded-full mb-4">
              <FontAwesomeIcon icon={faTools} className="text-amber-600 text-2xl" />
            </div>
            <h3 className="text-lg font-semibold text-amber-700 mb-2">Easy Booking</h3>
            <p className="text-gray-600 text-center text-sm">Book services in just a few clicks with instant confirmation.</p>
          </div>
          <div className="flex flex-col items-center p-6 rounded-xl bg-white shadow-md hover:shadow-xl transition-all duration-300 border-t-4 border-purple-500">
            <div className="w-16 h-16 flex items-center justify-center bg-purple-100 rounded-full mb-4">
              <FontAwesomeIcon icon={faHeadset} className="text-purple-600 text-2xl" />
            </div>
            <h3 className="text-lg font-semibold text-purple-700 mb-2">24/7 Support</h3>
            <p className="text-gray-600 text-center text-sm">Our dedicated team is available round the clock to assist you.</p>
          </div>
        </div>
      </div>

      {/* Service Categories and Top Services */}
      <div className="flex flex-wrap max-w-7xl mx-auto px-4 mb-16">
        <div className="w-full lg:w-1/2 p-4 lg:p-6">
          <div className="flex items-center mb-6">
            <div className="w-1 h-8 bg-green-500 mr-3"></div>
            <h3 className="text-2xl font-bold text-gray-800">Top Categories</h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            {data.map((category, index) => (
              <Link
                key={category.id}
                to={`/services/${category.id}`}
                className={`flex flex-col items-center p-6 rounded-xl transition-all duration-300 bg-gradient-to-br ${
                  index % 4 === 0 ? 'from-green-50 to-green-100' : 
                  index % 4 === 1 ? 'from-blue-50 to-blue-100' : 
                  index % 4 === 2 ? 'from-amber-50 to-amber-100' : 
                  'from-purple-50 to-purple-100'
                } hover:shadow-xl border border-gray-100`}
                ref={(el) => categoryRefs.current[index] = el}
              >
                <span className="text-4xl mb-3">{category.icon}</span>
                <span className="text-lg font-medium text-gray-800">{category.name}</span>
                <span className="mt-2 text-sm text-gray-500">Explore services →</span>
              </Link>
            ))}
          </div>
        </div>
        
        <div className="w-full lg:w-1/2 p-4 lg:p-6">
          <div className="flex items-center mb-6">
            <div className="w-1 h-8 bg-green-500 mr-3"></div>
            <h2 className="text-2xl font-bold text-gray-800">Top Services</h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            {data[0].subcategories.flatMap(sub =>
              sub.services.slice(0, 4).map(service => (
                <div
                  key={service.id}
                  className="border border-gray-200 rounded-xl p-5 bg-white shadow-sm hover:shadow-lg transition-all duration-300 cursor-pointer"
                  onClick={() => handleServiceSelect(service)}
                >
                  <div className="flex items-center mb-3">
                    <span className="text-3xl mr-3">{service.icon}</span>
                    <span className="text-lg font-medium text-gray-800">{service.name}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-gray-600">Starting from: <span className="font-bold text-green-600">₹{service.lowestPrice}</span></p>
                    <button className="px-3 py-1 bg-green-100 text-green-700 rounded-full text-sm font-medium hover:bg-green-200 transition-colors">Book</button>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="mt-6 text-center">
            <Link to="/service" className="inline-block px-6 py-3 bg-green-600 text-white font-medium rounded-full hover:bg-green-700 transition-colors shadow-md">
              View All Services
            </Link>
          </div>
        </div>
      </div>

      {/* Provider Modal */}
      <ProviderModal
        selectedService={selectedService}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Home;
