import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { data } from '../data';
import { 
  FiSearch, 
  FiFilter, 
  FiStar, 
  FiMapPin, 
  FiClock, 
  FiShield,
  FiChevronRight,
  FiArrowRight,
  FiCalendar,FiCheckCircle
} from 'react-icons/fi';

const ServiceCategoriesPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(data);
  const [activeFilter, setActiveFilter] = useState('all');
  const [popularCategories, setPopularCategories] = useState([]);

  useEffect(() => {
    // Set some categories as popular (you could determine this from actual data)
    setPopularCategories(data.slice(0, 3).map(cat => cat.id));
    
    // Filter categories based on search term
    if (searchTerm) {
      const filtered = data.filter(category => 
        category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        category.subcategories.some(sub => 
          sub.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredCategories(filtered);
    } else {
      // If no search term, apply category filter
      if (activeFilter === 'all') {
        setFilteredCategories(data);
      } else if (activeFilter === 'popular') {
        setFilteredCategories(data.filter(cat => popularCategories.includes(cat.id)));
      }
    }
  }, [searchTerm, activeFilter]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    setSearchTerm('');
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-green-600 to-teal-500 text-white">
        <div className="max-w-7xl mx-auto px-4 py-16 md:py-24">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Find the Perfect Service for Your Needs</h1>
            <p className="text-xl opacity-90 mb-8">
              Browse our comprehensive range of professional services, all delivered by verified experts.
            </p>
            
            {/* Search Bar */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search for services..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full py-4 px-5 pr-12 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
              />
              <FiSearch className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 text-xl" />
            </div>
          </div>
        </div>
      </div>

      {/* Trust Badges */}
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex flex-wrap justify-center gap-4 md:gap-8">
            <div className="flex items-center">
              <FiShield className="text-green-600 mr-2" />
              <span className="text-sm font-medium">Verified Providers</span>
            </div>
            <div className="flex items-center">
              <FiStar className="text-amber-500 mr-2" />
              <span className="text-sm font-medium">Top-Rated Services</span>
            </div>
            <div className="flex items-center">
              <FiClock className="text-blue-600 mr-2" />
              <span className="text-sm font-medium">Fast Service</span>
            </div>
            <div className="flex items-center">
              <FiMapPin className="text-red-500 mr-2" />
              <span className="text-sm font-medium">Pan India Coverage</span>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Category Filters */}
        <div className="mb-8 flex items-center space-x-2 overflow-x-auto pb-2">
          <button
            onClick={() => handleFilterChange('all')}
            className={`px-4 py-2 rounded-full whitespace-nowrap ${
              activeFilter === 'all' 
                ? 'bg-green-600 text-white' 
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
          >
            All Categories
          </button>
          <button
            onClick={() => handleFilterChange('popular')}
            className={`px-4 py-2 rounded-full whitespace-nowrap ${
              activeFilter === 'popular' 
                ? 'bg-green-600 text-white' 
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
          >
            Popular Services
          </button>
        </div>

        {/* Results Count */}
        <div className="mb-6 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">
            {filteredCategories.length} {filteredCategories.length === 1 ? 'Category' : 'Categories'} Available
          </h2>
          <div className="flex items-center text-sm text-gray-600">
            <FiFilter className="mr-1" />
            <span>Filter</span>
          </div>
        </div>

        {/* Categories Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCategories.map((category) => (
            <motion.div
              key={category.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-xl overflow-hidden border hover:shadow-md transition-shadow"
            >
              {/* Category Header */}
              <div className="h-40 bg-gradient-to-br from-green-50 to-teal-50 relative overflow-hidden">
                <div className="absolute inset-0 flex items-center justify-center">
                  <category.icon className="text-6xl text-green-600" />
                </div>
                {popularCategories.includes(category.id) && (
                  <div className="absolute top-4 right-4 bg-amber-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    Popular
                  </div>
                )}
              </div>
              
              {/* Category Content */}
              <div className="p-5">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{category.name}</h3>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {category.title || `Professional ${category.name} services for all your needs.`}
                </p>
                
                {/* Subcategories Preview */}
                <div className="mb-4">
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Services include:</h4>
                  <ul className="space-y-1">
                    {category.subcategories.slice(0, 3).map((sub) => (
                      <li key={sub.id} className="flex items-center text-gray-700">
                        <sub.icon className="text-green-600 mr-2 text-sm" />
                        <span>{sub.name}</span>
                      </li>
                    ))}
                    {category.subcategories.length > 3 && (
                      <li className="text-green-600 text-sm">
                        +{category.subcategories.length - 3} more services
                      </li>
                    )}
                  </ul>
                </div>
                
                {/* Call to Action */}
                <Link 
                  to={`/services/${category.id}`}
                  className="flex items-center justify-between w-full mt-2 py-2 px-4 bg-green-50 text-green-700 rounded-lg hover:bg-green-100 transition-colors"
                >
                  <span className="font-medium">Explore Services</span>
                  <FiChevronRight />
                </Link>
              </div>
            </motion.div>
          ))}
        </div>

        {/* No Results */}
        {filteredCategories.length === 0 && (
          <div className="text-center py-12">
            <h3 className="text-xl font-medium text-gray-800 mb-2">No services found</h3>
            <p className="text-gray-600 mb-6">Try adjusting your search or browse all categories</p>
            <button
              onClick={() => {setSearchTerm(''); setActiveFilter('all');}}
              className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
            >
              View All Services
            </button>
          </div>
        )}
      </div>

      {/* Featured Services Section */}
      <div className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-8">Featured Services</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {data.slice(0, 3).map((category) => (
              <div key={category.id} className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex items-center mb-4">
                  <category.icon className="text-3xl text-green-600 mr-3" />
                  <h3 className="text-lg font-semibold">{category.name}</h3>
                </div>
                
                <p className="text-gray-600 mb-4">
                  {category.subcategories[0]?.services[0]?.name || 'Professional service'} starting at just 
                  <span className="font-bold text-green-700"> ₹{
                    Math.min(...category.subcategories.flatMap(sub => 
                      sub.services?.map(service => service.lowestPrice) || [999]
                    ))
                  }</span>
                </p>
                
                <Link 
                  to={`/services/${category.id}`}
                  className="flex items-center text-green-600 hover:text-green-700 font-medium"
                >
                  View Details
                  <FiArrowRight className="ml-1" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-gray-800 text-center mb-12">How It Works</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <FiSearch className="text-2xl text-green-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">1. Browse Services</h3>
              <p className="text-gray-600">
                Explore our wide range of services and choose the one that meets your requirements.
              </p>
            </div>
            
            <div className="text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <FiCalendar className="text-2xl text-green-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">2. Book an Appointment</h3>
              <p className="text-gray-600">
                Select your preferred date and time, and book your service with our verified professionals.
              </p>
            </div>
            
            <div className="text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <FiCheckCircle className="text-2xl text-green-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">3. Get Service Delivered</h3>
              <p className="text-gray-600">
                Our experts will arrive at your location and provide high-quality service as per your booking.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Customer Testimonials */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl font-bold text-gray-800 text-center mb-12">What Our Customers Say</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex text-yellow-400 mb-3">
                <FiStar />
                <FiStar />
                <FiStar />
                <FiStar />
                <FiStar />
              </div>
              <p className="text-gray-600 mb-4">
                "The service was excellent! The technician arrived on time and fixed my AC in no time. Highly recommended!"
              </p>
              <div className="font-medium">- Rahul Sharma, Delhi</div>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex text-yellow-400 mb-3">
                <FiStar />
                <FiStar />
                <FiStar />
                <FiStar />
                <FiStar />
              </div>
              <p className="text-gray-600 mb-4">
                "Very professional service. The plumber was knowledgeable and fixed the leak quickly. Will definitely use again."
              </p>
              <div className="font-medium">- Priya Patel, Mumbai</div>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex text-yellow-400 mb-3">
                <FiStar />
                <FiStar />
                <FiStar />
                <FiStar />
                <FiStar />
              </div>
              <p className="text-gray-600 mb-4">
                "Booking was easy and the service was top-notch. The electrician was friendly and did a great job installing my ceiling fan."
              </p>
              <div className="font-medium">- Amit Kumar, Bangalore</div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-green-600 to-teal-500 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
          <p className="text-xl opacity-90 mb-8 max-w-2xl mx-auto">
            Book your service today and experience the difference with our professional service providers.
          </p>
          <Link 
            to="/services"
            className="inline-flex items-center px-8 py-3 bg-white text-green-700 rounded-lg font-medium hover:bg-gray-100 transition-colors"
          >
            Browse All Services
            <FiArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceCategoriesPage;
