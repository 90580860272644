import React from 'react';
import { motion } from 'framer-motion';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="relative w-full h-[350px] md:h-[400px] overflow-hidden">
      {/* SVG Pattern Background - Hidden on small screens */}
      <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-teal-500">
        <svg className="absolute w-full h-full hidden sm:block" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="grid-pattern" width="40" height="40" patternUnits="userSpaceOnUse">
              <path d="M 40 0 L 0 0 0 40" fill="none" stroke="rgba(255, 255, 255, 0.1)" strokeWidth="1" />
            </pattern>
            <pattern id="circles-pattern" width="100" height="100" patternUnits="userSpaceOnUse">
              <circle cx="50" cy="50" r="20" fill="none" stroke="rgba(255, 255, 255, 0.08)" strokeWidth="1.5" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid-pattern)" />
          <rect width="100%" height="100%" fill="url(#circles-pattern)" />
          
          {/* Decorative Elements */}
          <circle cx="15%" cy="20%" r="50" fill="rgba(255, 255, 255, 0.05)" />
          <circle cx="85%" cy="60%" r="80" fill="rgba(255, 255, 255, 0.07)" />
          <circle cx="40%" cy="90%" r="40" fill="rgba(255, 255, 255, 0.03)" />
        </svg>
      </div>
      
      {/* Content */}
      <div className="relative flex flex-col items-center justify-center h-full text-white text-center px-4 z-10">
        <motion.h1 
          className="text-3xl md:text-5xl font-bold mb-3"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Welcome to TribeLink
        </motion.h1>
        
        <motion.p 
          className="text-lg md:text-xl mb-6 max-w-2xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          Your one-stop solution for all daily services.
        </motion.p>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <Link to="/service">
            <button className="bg-white text-green-600 px-6 py-2.5 rounded-full font-medium flex items-center hover:bg-opacity-90 transition-all">
              Explore Services
              <FiArrowRight className="ml-2" />
            </button>
          </Link>
        </motion.div>
      </div>
      
      {/* Floating Elements - Only visible on medium screens and up */}
      <motion.div 
        className="absolute top-1/4 left-[10%] w-12 h-12 rounded-lg bg-white bg-opacity-10 hidden md:block"
        animate={{ 
          y: [0, -15, 0],
          rotate: [0, 5, 0]
        }}
        transition={{ 
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      
      <motion.div 
        className="absolute bottom-1/4 right-[15%] w-16 h-16 rounded-full bg-white bg-opacity-10 hidden md:block"
        animate={{ 
          y: [0, 20, 0],
          x: [0, -10, 0]
        }}
        transition={{ 
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </div>
  );
};

export default HeroSection;
