import { useState, useEffect } from "react";

const HOST = "https://prod.tribelink.co.in";
// const HOST = "http://localhost:3000";

export const useSearchData = () => {
  const [messageId, setMessageId] = useState("");
  const [results, setResults] = useState([]);
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!messageId) return;

    const sse = new EventSource(`${HOST}/clientApis/events/v2?messageId=${messageId}`, {
      withCredentials: true,
    });

    sse.onmessage = (e) => {
      console.log(e.data);
    };

    sse.addEventListener("on_search", (e) => {
      setLoading(false);
      const newResult = JSON.parse(e.data);

      const providers =
        newResult.message?.catalog?.["bpp/providers"] ||
        newResult.message?.catalog?.bpp_providers ||
        [];

      const items = providers.flatMap((provider) => {
        const providerItems = provider.items || [];
        return providerItems.map((item) => ({
          ...item,
          provider_info: {
            id: provider.id,
            name: provider.descriptor?.name,
            description: provider.descriptor?.short_desc,
            images: provider.descriptor?.images,
            locations: provider.locations,
            fulfillments: provider.fulfillments,
          },
        }));
      });

      const fulfillments =
        newResult.message?.catalog?.["bpp/fulfillments"] ||
        newResult.message?.catalog?.bpp_fulfillments ||
        [];

      if (fulfillments.length > 0) {
        console.log("Available fulfillment options:", fulfillments);
      }

      if (items.length > 0) {
        setResults((prevResults) => [...items, ...prevResults]);
        const uniqueCategories = [...new Set(items.map((item) => item.category_id))].filter(Boolean);
        setCategories(uniqueCategories);
      } else {
        console.log("No items found in the expected structure", newResult);
        setResults((prevResults) => [newResult, ...prevResults]);
      }
    });

    sse.onerror = () => {
      sse.close();
    };

    return () => {
      sse.close();
    };
  }, [messageId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputValue || (!init && loading)) return;

    setInit(false);
    setLoading(true);
    setResults([]);
    setCategories([]);

    try {
      const response = await fetch(`${HOST}/clientApis/v1/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          context: {
            city: "std:080",
          },
          search_string: inputValue,
        }),
      }).then((r) => r.json());

      if (response) {
        setMessageId(response.context.message_id);
      } else {
        alert("Error sending request");
      }
    } catch (error) {
      console.error("Network error:", error);
      setLoading(false);
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const filteredResults = selectedCategory
    ? results.filter((item) => item.category_id === selectedCategory)
    : results;

  return {
    messageId,
    results,
    init,
    loading,
    inputValue,
    categories,
    selectedCategory,
    selectedItem,
    showModal,
    filteredResults,
    setInputValue,
    setSelectedCategory,
    handleSubmit,
    handleItemClick,
    closeModal,
  };
};