// src/components/MetaTags.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = ({ 
  title = "TribeLink | Find Quality Services Near You",
  description = "Discover and book verified professional services across India. Browse categories, compare prices, and book services with instant confirmation.",
  keywords = "services, marketplace, ONDC, professional services, home services, booking, India",
  ogTitle = "Service Marketplace | Quality Services at Your Fingertips",
  ogDescription = "Book verified professionals for all your service needs. 4.8/5 rated services with pan-India coverage and 24/7 support.",
  ogImage = "/images/og-home.jpg",
  twitterTitle = "TribeLink | Book Services Online",
  twitterDescription = "Find and book quality services from verified professionals. Fast service, ONDC verified with pan-India coverage.",
  twitterImage = "/images/twitter-home.jpg",
  canonicalUrl = window.location.href
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImage} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default MetaTags;
