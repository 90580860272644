import { 
  FiTool, FiZap, FiDroplet, FiBriefcase, FiHome, 
  FiThermometer, FiWind, FiMonitor, FiCpu, FiSmartphone, 
  FiServer, FiPackage, FiShield, FiActivity, FiHeart, 
  FiBell, FiUsers, FiSun, FiCloudRain, FiCloud, 
  FiBug, FiCoffee, FiAward
} from "react-icons/fi";


import {
  HiOutlineWrenchScrewdriver, HiOutlineBolt, HiOutlineWrench, 
  HiOutlineHome, HiOutlineComputerDesktop, HiOutlineWifi, 
  HiOutlineBugAnt, HiOutlineBeaker, HiOutlineHeart
} from "react-icons/hi2";

import {
  TbAirConditioning, TbWashMachine, TbDeviceDesktop, 
  TbDeviceLaptop, TbPlant, TbSpray, TbBrain
} from "react-icons/tb";

import {
  IoWaterOutline, IoFastFoodOutline, IoBedOutline, 
  IoConstructOutline, IoFlowerOutline, IoFitnessOutline
} from "react-icons/io5";

export const data = [
  {
    "id": "1",
    "name": "Home Repair",
    "icon": FiTool,
    "title": "Home Repair Services",
    "subcategories": [
      {
        "id": "1-1",
        "name": "Electrical",
        "icon": FiZap,
        "services": [
          {
            "id": "1-1-1",
            "name": "Ceiling Fan Installation",
            "lowestPrice": 249,
            "icon": FiWind,
            "serviceProviders": [
              {
                "providerName": "FixIt Electricals",
                "price": 249,
                "rating": 4.5,
                "yearsOfExperience": 7,
                "specialties": ["Residential Electrical", "Fan Installation"],
                "icon": HiOutlineWrenchScrewdriver
              },
              {
                "providerName": "Bright Lights Co.",
                "price": 299,
                "rating": 4.2,
                "yearsOfExperience": 5,
                "specialties": ["Commercial Electrical", "Fan Installation"],
                "icon": HiOutlineWrenchScrewdriver
              }
            ]
          },
          {
            "id": "1-1-2",
            "name": "Wiring Replacement",
            "lowestPrice": 349,
            "icon": HiOutlineBolt,
            "serviceProviders": [
              {
                "providerName": "Wiring Solutions Inc.",
                "price": 349,
                "rating": 4.8,
                "yearsOfExperience": 10,
                "specialties": ["Residential Wiring", "Remodeling"],
                "icon": HiOutlineWrenchScrewdriver
              },
              {
                "providerName": "Elite Electric",
                "price": 399,
                "rating": 4.6,
                "yearsOfExperience": 8,
                "specialties": ["Electrical Upgrades", "Troubleshooting"],
                "icon": HiOutlineWrenchScrewdriver
              }
            ]
          },
          {
            "id": "1-1-3",
            "name": "Electrical Panel Upgrade",
            "lowestPrice": 599,
            "icon": FiServer,
            "serviceProviders": [
              {
                "providerName": "PowerUp Electrical",
                "price": 599,
                "rating": 4.9,
                "yearsOfExperience": 12,
                "specialties": ["Panel Upgrades", "Circuit Installation"],
                "icon": HiOutlineWrenchScrewdriver
              },
              {
                "providerName": "Circuit Masters",
                "price": 649,
                "rating": 4.7,
                "yearsOfExperience": 9,
                "specialties": ["Electrical Safety", "Code Compliance"],
                "icon": HiOutlineWrenchScrewdriver
              }
            ]
          }
        ]
      },
      {
        "id": "1-2",
        "name": "Plumbing",
        "icon": IoWaterOutline,
        "services": [
          {
            "id": "1-2-1",
            "name": "Leak Fixing",
            "lowestPrice": 199,
            "icon": FiDroplet,
            "serviceProviders": [
              {
                "providerName": "AquaFix Plumbing",
                "price": 199,
                "rating": 4.6,
                "yearsOfExperience": 12,
                "specialties": ["Residential Plumbing", "Leak Detection"],
                "icon": HiOutlineWrench
              },
              {
                "providerName": "PipeMasters",
                "price": 220,
                "rating": 4.4,
                "yearsOfExperience": 6,
                "specialties": ["Drain Cleaning", "Leak Repair"],
                "icon": HiOutlineWrench
              }
            ]
          },
          {
            "id": "1-2-2",
            "name": "Pipe Installation",
            "lowestPrice": 299,
            "icon": IoWaterOutline,
            "serviceProviders": [
              {
                "providerName": "FlowTech Plumbing",
                "price": 299,
                "rating": 4.7,
                "yearsOfExperience": 9,
                "specialties": ["Pipe Installation", "Remodeling"],
                "icon": HiOutlineWrench
              },
              {
                "providerName": "WaterWorks Plumbing",
                "price": 319,
                "rating": 4.3,
                "yearsOfExperience": 8,
                "specialties": ["Residential Plumbing", "Commercial Plumbing"],
                "icon": HiOutlineWrench
              }
            ]
          }
        ]
      },
      {
        "id": "1-3",
        "name": "Carpentry",
        "icon": IoConstructOutline,
        "services": [
          {
            "id": "1-3-1",
            "name": "Cabinet Installation",
            "lowestPrice": 399,
            "icon": FiBriefcase,
            "serviceProviders": [
              {
                "providerName": "Cabinet Crafters",
                "price": 399,
                "rating": 4.7,
                "yearsOfExperience": 9,
                "specialties": ["Custom Cabinets", "Kitchen Remodeling"],
                "icon": IoConstructOutline
              },
              {
                "providerName": "Woodwork Wizards",
                "price": 449,
                "rating": 4.5,
                "yearsOfExperience": 7,
                "specialties": ["Cabinet Refinishing", "Bathroom Vanities"],
                "icon": IoConstructOutline
              }
            ]
          },
          {
            "id": "1-3-2",
            "name": "Deck Repair",
            "lowestPrice": 349,
            "icon": FiHome,
            "serviceProviders": [
              {
                "providerName": "Deck Doctors",
                "price": 349,
                "rating": 4.6,
                "yearsOfExperience": 8,
                "specialties": ["Deck Restoration", "Staining"],
                "icon": IoConstructOutline
              },
              {
                "providerName": "Outdoor Living Experts",
                "price": 389,
                "rating": 4.4,
                "yearsOfExperience": 6,
                "specialties": ["Deck Building", "Outdoor Structures"],
                "icon": IoConstructOutline
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "id": "2",
    "name": "Appliance Services",
    "icon": FiPackage,
    "title": "Appliance Repair Services",
    "subcategories": [
      {
        "id": "2-1",
        "name": "Refrigerator",
        "icon": FiThermometer,
        "services": [
          {
            "id": "2-1-1",
            "name": "Refrigerator Repair",
            "lowestPrice": 399,
            "icon": TbAirConditioning,
            "serviceProviders": [
              {
                "providerName": "CoolTech Appliances",
                "price": 399,
                "rating": 4.5,
                "yearsOfExperience": 10,
                "specialties": ["Refrigerator Repair", "Cooling Systems"],
                "icon": HiOutlineWrench
              },
              {
                "providerName": "Home Appliance Solutions",
                "price": 450,
                "rating": 4.2,
                "yearsOfExperience": 7,
                "specialties": ["Refrigerator Repair", "Appliance Maintenance"],
                "icon": HiOutlineWrench
              }
            ]
          },
          {
            "id": "2-1-2",
            "name": "Fridge Defrost Repair",
            "lowestPrice": 349,
            "icon": FiThermometer,
            "serviceProviders": [
              {
                "providerName": "Chill Masters",
                "price": 349,
                "rating": 4.4,
                "yearsOfExperience": 6,
                "specialties": ["Defrosting Issues", "Cooling Systems"],
                "icon": HiOutlineWrench
              },
              {
                "providerName": "Apex Appliance Repair",
                "price": 389,
                "rating": 4.0,
                "yearsOfExperience": 5,
                "specialties": ["Refrigerator Repair", "General Appliance Repair"],
                "icon": HiOutlineWrench
              }
            ]
          }
        ]
      },
      {
        "id": "2-2",
        "name": "Washing Machine",
        "icon": TbWashMachine,
        "services": [
          {
            "id": "2-2-1",
            "name": "Washing Machine Repair",
            "lowestPrice": 349,
            "icon": TbWashMachine,
            "serviceProviders": [
              {
                "providerName": "SpinCycle Services",
                "price": 349,
                "rating": 4.6,
                "yearsOfExperience": 5,
                "specialties": ["Washing Machine Repair", "Appliance Installation"],
                "icon": HiOutlineWrench
              },
              {
                "providerName": "Laundry Solutions Co.",
                "price": 369,
                "rating": 4.3,
                "yearsOfExperience": 6,
                "specialties": ["Washing Machines", "Dryers"],
                "icon": HiOutlineWrench
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "id": "3",
    "name": "Cleaning Services",
    "icon": TbSpray,
    "title": "Cleaning Services",
    "subcategories": [
      {
        "id": "3-1",
        "name": "Home Cleaning",
        "icon": FiHome,
        "services": [
          {
            "id": "3-1-1",
            "name": "Deep Cleaning",
            "lowestPrice": 599,
            "icon": TbSpray,
            "serviceProviders": [
              {
                "providerName": "Sparkle Clean Co.",
                "price": 599,
                "rating": 4.8,
                "yearsOfExperience": 3,
                "specialties": ["Deep Cleaning", "Move-In/Out Cleaning"],
                "icon": FiUsers
              },
              {
                "providerName": "Green Clean Team",
                "price": 649,
                "rating": 4.4,
                "yearsOfExperience": 4,
                "specialties": ["Eco-Friendly Cleaning", "Residential Cleaning"],
                "icon": FiUsers
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "id": "4",
    "name": "Landscaping Services",
    "icon": IoFlowerOutline,
    "title": "Landscaping Services",
    "subcategories": [
      {
        "id": "4-1",
        "name": "Garden Design",
        "icon": TbPlant,
        "services": [
          {
            "id": "4-1-1",
            "name": "Garden Landscaping",
            "lowestPrice": 999,
            "icon": IoFlowerOutline,
            "serviceProviders": [
              {
                "providerName": "GreenScapes",
                "price": 999,
                "rating": 4.8,
                "yearsOfExperience": 6,
                "specialties": ["Landscape Design", "Garden Planning"],
                "icon": TbPlant
              },
              {
                "providerName": "Lush Landscapes",
                "price": 1049,
                "rating": 4.7,
                "yearsOfExperience": 7,
                "specialties": ["Sustainable Landscaping", "Garden Maintenance"],
                "icon": TbPlant
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "id": "5",
    "name": "Pest Control Services",
    "icon": HiOutlineBugAnt,
    "title": "Pest Control Services",
    "subcategories": [
      {
        "id": "5-1",
        "name": "General Pest Control",
        "icon": HiOutlineBugAnt,
        "services": [
          {
            "id": "5-1-1",
            "name": "Ant Control",
            "lowestPrice": 199,
            "icon": HiOutlineBugAnt,
            "serviceProviders": [
              {
                "providerName": "Pest Busters",
                "price": 199,
                "rating": 4.5,
                "yearsOfExperience": 5,
                "specialties": ["General Pest Control", "Residential Pest Management"],
                "icon": FiShield
              },
              {
                "providerName": "Bug Stop Services",
                "price": 220,
                "rating": 4.3,
                "yearsOfExperience": 6,
                "specialties": ["Insect Control", "Rodent Control"],
                "icon": FiShield
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "id": "6",
    "name": "Tech Support",
    "icon": FiCpu,
    "title": "Tech Support Services",
    "subcategories": [
      {
        "id": "6-1",
        "name": "Computer Repair",
        "icon": HiOutlineComputerDesktop,
        "services": [
          {
            "id": "6-1-1",
            "name": "Virus Removal",
            "lowestPrice": 149,
            "icon": FiShield,
            "serviceProviders": [
              {
                "providerName": "TechGuard Solutions",
                "price": 149,
                "rating": 4.7,
                "yearsOfExperience": 5,
                "specialties": ["Virus Removal", "Malware Protection"],
                "icon": TbDeviceDesktop
              },
              {
                "providerName": "PC Fixers",
                "price": 179,
                "rating": 4.5,
                "yearsOfExperience": 4,
                "specialties": ["PC Optimization", "Virus Removal"],
                "icon": TbDeviceLaptop
              }
            ]
          }
        ]
      },
      {
        "id": "6-2",
        "name": "Smart Home Support",
        "icon": FiSmartphone,
        "services": [
          {
            "id": "6-2-1",
            "name": "Smart Device Installation",
            "lowestPrice": 199,
            "icon": FiSmartphone,
            "serviceProviders": [
              {
                "providerName": "SmartHome Solutions",
                "price": 199,
                "rating": 4.7,
                "yearsOfExperience": 4,
                "specialties": ["Smart Lock Installation", "Device Integration"],
                "icon": FiHome
              },
              {
                "providerName": "TechConnect",
                "price": 229,
                "rating": 4.6,
                "yearsOfExperience": 6,
                "specialties": ["IoT Setup", "Home Automation"],
                "icon": FiHome
              }
            ]
          },
          {
            "id": "6-2-2",
            "name": "Home Wi-Fi Optimization",
            "lowestPrice": 149,
            "icon": HiOutlineWifi,
            "serviceProviders": [
              {
                "providerName": "NetSpeed Pros",
                "price": 149,
                "rating": 4.8,
                "yearsOfExperience": 5,
                "specialties": ["Wi-Fi Setup", "Signal Boosting"],
                "icon": HiOutlineWifi
              },
              {
                "providerName": "FastConnect",
                "price": 179,
                "rating": 4.6,
                "yearsOfExperience": 4,
                "specialties": ["Network Setup", "Troubleshooting"],
                "icon": HiOutlineWifi
              }
            ]
          }
        ]
      }
    ]
  }
]
