import React, { useContext, useState } from 'react';
import { CartContext } from '../contexts/cartContext';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FiStar,
  FiX,
  FiShoppingCart,
  FiCalendar,
  FiClock,
  FiCheckCircle,
  FiMapPin,
  FiPhone,
  FiMail,
  FiTruck,
  FiPackage,
  FiUser
} from 'react-icons/fi';

const ProviderModal = ({ selectedService, isOpen, onClose, providerData }) => {
  const { addToCart } = useContext(CartContext);
  const [selectedFulfillment, setSelectedFulfillment] = useState('F0');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  if (!isOpen || !selectedService) return null;

  // Extract provider details from the ONDC catalog structure
  const provider = providerData?.bpp_providers?.[0] || {};
  const providerDescriptor = provider?.descriptor || {};
  const providerLocations = provider?.locations || [];
  const providerFulfillments = provider?.fulfillments || [];
  const providerTags = provider?.tags || [];

  // Extract timing information
  const timingTag = providerTags.find(tag => tag.code === 'timing');
  const timingList = timingTag?.list || [];
  const timeFrom = timingList.find(item => item.code === 'time_from')?.value || '1000';
  const timeTo = timingList.find(item => item.code === 'time_to')?.value || '2100';

  // Format time from 24hr format (1000) to 12hr format (10:00 AM)
  const formatTime = (time) => {
    if (!time || time.length !== 4) return '';
    const hours = parseInt(time.substring(0, 2));
    const minutes = time.substring(2, 4);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  };

  // Generate available time slots
  const generateTimeSlots = () => {
    const slots = [];
    const startHour = parseInt(timeFrom.substring(0, 2));
    const endHour = parseInt(timeTo.substring(0, 2));
    
    for (let hour = startHour; hour <= endHour; hour++) {
      slots.push(`${hour.toString().padStart(2, '0')}00`);
      if (hour < endHour) {
        slots.push(`${hour.toString().padStart(2, '0')}30`);
      }
    }
    
    return slots;
  };

  // Generate available dates (next 7 days excluding holidays)
  const generateAvailableDates = () => {
    const dates = [];
    const today = new Date();
    const location = providerLocations[0] || {};
    const holidays = location?.time?.schedule?.holidays || [];
    
    for (let i = 0; i < 14; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      
      // Format date as YYYY-MM-DD for comparison with holidays
      const formattedDate = date.toISOString().split('T')[0];
      
      // Skip if it's a holiday
      if (holidays.includes(formattedDate)) continue;
      
      dates.push({
        value: formattedDate,
        label: date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
      });
      
      // Only show 7 available dates
      if (dates.length >= 7) break;
    }
    
    return dates;
  };

  const timeSlots = generateTimeSlots();
  const availableDates = generateAvailableDates();

  const handleAddToCart = () => {
    if (!selectedDate || !selectedTime) {
      toast.error("Please select a date and time for your service", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return;
    }

    const selectedFulfillmentObj = providerFulfillments.find(f => f.id === selectedFulfillment);
    
    const item = {
      id: `${provider.id}-${selectedService.id}-${Date.now()}`,
      name: selectedService.name || providerDescriptor.name,
      price: selectedService.price?.value || "Price on request",
      quantity: 1,
      provider: {
        id: provider.id,
        name: providerDescriptor.name,
        image: providerDescriptor.images?.[0],
        fulfillment: selectedFulfillmentObj
      },
      service: selectedService,
      booking: {
        date: selectedDate,
        time: selectedTime,
        fulfillmentType: selectedFulfillmentObj?.type
      }
    };

    addToCart(item);
    
    toast.success(`${selectedService.name || 'Service'} has been added to your cart!`, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const handleBookNow = () => {
    handleAddToCart();
    // You could redirect to checkout here
    toast.info(`Proceeding to checkout!`, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={onClose}
          />
          
          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed inset-x-0 bottom-0 z-50 bg-white rounded-t-2xl max-h-[90vh] overflow-y-auto"
            style={{ boxShadow: '0 -4px 20px rgba(0,0,0,0.1)' }}
          >
            {/* Modal Header */}
            <div className="sticky top-0 bg-white z-10 border-b border-gray-100">
              <div className="flex justify-between items-center p-4">
                <h3 className="text-xl font-semibold text-gray-800">
                  {selectedService.descriptor?.name || providerDescriptor.name}
                </h3>
                <button 
                  onClick={onClose}
                  className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                >
                  <FiX size={24} />
                </button>
              </div>
              
              <div className="px-4 pb-4 flex items-center justify-between">
                <div className="flex items-center">
                  <div className="h-16 w-16 rounded-lg overflow-hidden mr-4">
                    <img 
                      src={providerDescriptor.images?.[0] || selectedService.descriptor?.images?.[0]} 
                      alt={providerDescriptor.name}
                      className="h-full w-full object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/150?text=No+Image";
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900">{providerDescriptor.name}</h4>
                    <p className="text-sm text-gray-500">{providerDescriptor.short_desc}</p>
                  </div>
                </div>
                
                {selectedService.price && (
                  <div className="text-xl font-bold text-green-600">
                    ₹{selectedService.price.value}
                  </div>
                )}
              </div>
            </div>
            
            {/* Service Details */}
            <div className="p-4 bg-green-50 mb-4">
              <h4 className="font-medium text-gray-800 mb-2">Service Information</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="flex items-center text-sm text-gray-600">
                  <FiClock className="mr-2 text-green-600" />
                  <span>Hours: {formatTime(timeFrom)} - {formatTime(timeTo)}</span>
                </div>
                
                <div className="flex items-center text-sm text-gray-600">
                  <FiCalendar className="mr-2 text-green-600" />
                  <span>Available: {timingList.find(item => item.code === 'day_from')?.value || '1'}-{timingList.find(item => item.code === 'day_to')?.value || '7'} days a week</span>
                </div>
                
                <div className="flex items-center text-sm text-gray-600">
                  <FiCheckCircle className="mr-2 text-green-600" />
                  <span>100% service guarantee</span>
                </div>
                
                <div className="flex items-center text-sm text-gray-600">
                  <FiMapPin className="mr-2 text-green-600" />
                  <span>
                    {providerLocations[0]?.address?.city}, {providerLocations[0]?.address?.state}
                  </span>
                </div>
              </div>
            </div>
            
            {/* Provider Description */}
            {providerDescriptor.long_desc && (
              <div className="px-4 mb-4">
                <h4 className="font-medium text-gray-800 mb-2">About Provider</h4>
                <p className="text-sm text-gray-600">{providerDescriptor.long_desc}</p>
              </div>
            )}
            
            {/* Fulfillment Options */}
            <div className="px-4 mb-4">
              <h4 className="font-medium text-gray-800 mb-2">Service Fulfillment</h4>
              <div className="grid grid-cols-1 gap-2">
                {providerFulfillments.map((fulfillment) => (
                  <div 
                    key={fulfillment.id}
                    className={`border rounded-lg p-3 cursor-pointer transition-colors ${
                      selectedFulfillment === fulfillment.id 
                        ? 'border-green-500 bg-green-50' 
                        : 'border-gray-200 hover:border-green-300'
                    }`}
                    onClick={() => setSelectedFulfillment(fulfillment.id)}
                  >
                    <div className="flex items-center">
                      {fulfillment.type === 'Delivery' && <FiTruck className="mr-2 text-green-600" />}
                      {fulfillment.type === 'Self-Pickup' && <FiPackage className="mr-2 text-green-600" />}
                      {fulfillment.type === 'Buyer-Delivery' && <FiUser className="mr-2 text-green-600" />}
                      <span className="font-medium">{fulfillment.type}</span>
                    </div>
                    
                    {fulfillment.contact && (
                      <div className="mt-2 text-sm text-gray-600 grid grid-cols-1 gap-1">
                        {fulfillment.contact.phone && (
                          <div className="flex items-center">
                            <FiPhone className="mr-2 text-gray-400" size={14} />
                            <span>{fulfillment.contact.phone}</span>
                          </div>
                        )}
                        {fulfillment.contact.email && (
                          <div className="flex items-center">
                            <FiMail className="mr-2 text-gray-400" size={14} />
                            <span>{fulfillment.contact.email}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            
            {/* Booking Section */}
            <div className="px-4 mb-4">
              <h4 className="font-medium text-gray-800 mb-2">Select Date & Time</h4>
              
              {/* Date Selection */}
              <div className="mb-3">
                <label className="block text-sm text-gray-600 mb-1">Date</label>
                <select 
                  className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-green-300 focus:outline-none"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                >
                  <option value="">Select a date</option>
                  {availableDates.map((date, index) => (
                    <option key={index} value={date.value}>
                      {date.label}
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Time Selection */}
              <div className="mb-3">
                <label className="block text-sm text-gray-600 mb-1">Time</label>
                <select 
                  className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-green-300 focus:outline-none"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  disabled={!selectedDate}
                >
                  <option value="">Select a time</option>
                  {timeSlots.map((slot, index) => (
                    <option key={index} value={slot}>
                      {formatTime(slot)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            {/* Location */}
            {providerLocations.length > 0 && (
              <div className="px-4 mb-4">
                <h4 className="font-medium text-gray-800 mb-2">Location</h4>
                <div className="border border-gray-200 rounded-lg p-3">
                  <div className="text-sm text-gray-600">
                    <p>
                      {providerLocations[0].address.building}, {providerLocations[0].address.street}
                    </p>
                    <p>
                      {providerLocations[0].address.locality}, {providerLocations[0].address.city}, {providerLocations[0].address.state}
                    </p>
                    <p>{providerLocations[0].address.area_code}</p>
                  </div>
                </div>
              </div>
            )}
            
            {/* Modal Footer */}
            <div className="sticky bottom-0 bg-white border-t border-gray-100 p-4 flex flex-col sm:flex-row gap-3">
              <button 
                onClick={handleAddToCart}
                className="flex-1 px-4 py-3 bg-white border border-green-600 text-green-600 rounded-lg font-medium hover:bg-green-50 transition-colors flex items-center justify-center"
              >
                <FiShoppingCart className="mr-2" />
                Add to Cart
              </button>
              <button 
                onClick={handleBookNow}
                className="flex-1 px-4 py-3 bg-green-600 text-white rounded-lg font-medium hover:bg-green-700 transition-colors flex items-center justify-center"
              >
                Book Now
              </button>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ProviderModal;
