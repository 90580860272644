import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [location, setLocation] = useState({ city: '', state: '' });
  const [scrolled, setScrolled] = useState(false);

  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to fetch the user's current location using Geolocation API
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        await getCityStateFromCoordinates(latitude, longitude);
      });
    } else {
      console.log('Geolocation is not supported by your browser.');
    }
  };

  // Function to reverse geocode the latitude and longitude using Google Maps API
  const getCityStateFromCoordinates = async (lat, lng) => {
    const apiKey = ''; 
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || 'Unknown City';
        const state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || 'Unknown State';

        setLocation({ city, state });
      }
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    fetchLocation();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed top-0 w-full z-50 transition-all duration-300 ${scrolled ? 'bg-green-800 shadow-lg' : 'bg-green-700'}`}>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center p-4 md:px-8">
          {/* Logo and location */}
          <div className="flex items-center">
            <Link to="/" className="text-xl font-bold mr-4 text-white flex items-center">
              <i className="fas fa-link mr-2"></i>
              TribeLink
            </Link>
            <div className="hidden sm:flex items-center">
              <i className="fas fa-map-marker-alt text-white mr-2"></i>
              <div className="bg-green-600 text-white py-1 px-3 rounded-full text-sm">
                {location.city ? `${location.city}, ${location.state}` : 'Fetching location...'}
              </div>
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            <Link to="/" className="text-white hover:text-green-300 transition duration-150 ease-in-out">Home</Link>
            <Link to="/service" className="text-white hover:text-green-300 transition duration-150 ease-in-out">Services</Link>
            <Link to="/search" className="text-white hover:text-green-300 transition duration-150 ease-in-out">ONDC Search</Link>
            <Link to="/about" className="text-white hover:text-green-300 transition duration-150 ease-in-out">About Us</Link>
            <div className="flex items-center space-x-4">
              <Link to="/cart" className="text-white hover:text-green-300 transition duration-150 ease-in-out relative">
                <i className="fas fa-shopping-cart text-lg"></i>
                <span className="absolute -top-2 -right-2 bg-yellow-500 text-xs rounded-full h-4 w-4 flex items-center justify-center">0</span>
              </Link>
              <Link to="/account" className="bg-white text-green-700 hover:bg-green-100 transition duration-150 ease-in-out py-1.5 px-4 rounded-full flex items-center">
                <i className="fas fa-user mr-2"></i>
                <span>Account</span>
              </Link>
            </div>
          </div>

          {/* Mobile menu toggle */}
          <div className="md:hidden flex items-center">
            <button
              className="text-white focus:outline-none"
              onClick={toggleMobileMenu}
              aria-label="Toggle menu"
            >
              <i className={`fas ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'} text-xl`}></i>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <motion.div 
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden bg-green-700 text-white p-4 shadow-inner"
          >
            <div className="flex items-center mb-4 sm:hidden">
              <i className="fas fa-map-marker-alt text-white mr-2"></i>
              <div className="bg-green-600 text-white py-1 px-3 rounded-full text-sm">
                {location.city ? `${location.city}, ${location.state}` : 'Fetching location...'}
              </div>
            </div>
            
            <Link to="/" className="block py-2 hover:bg-green-600 px-2 rounded transition duration-150 ease-in-out" onClick={() => setIsMobileMenuOpen(false)}>Home</Link>
            <Link to="/service" className="block py-2 hover:bg-green-600 px-2 rounded transition duration-150 ease-in-out" onClick={() => setIsMobileMenuOpen(false)}>Services</Link>
            <Link to="/about" className="block py-2 hover:bg-green-600 px-2 rounded transition duration-150 ease-in-out" onClick={() => setIsMobileMenuOpen(false)}>About Us</Link>
            <Link to="/cart" className="block py-2 hover:bg-green-600 px-2 rounded transition duration-150 ease-in-out" onClick={() => setIsMobileMenuOpen(false)}>
              <i className="fas fa-shopping-cart mr-2"></i>
              Cart
            </Link>
            <Link to="/account" className="block py-2 hover:bg-green-600 px-2 rounded transition duration-150 ease-in-out" onClick={() => setIsMobileMenuOpen(false)}>
              <i className="fas fa-user mr-2"></i>
              Account
            </Link>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
