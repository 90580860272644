import { Helmet } from 'react-helmet-async';

const ServicePageMeta = ({ category, selectedSubcategory, selectedService }) => {
  // Fallback values if data isn't loaded yet
  const categoryName = category?.name || 'Services';
  const subcategoryName = selectedSubcategory?.name || '';
  
  // Dynamic title based on what's selected
  const title = selectedService 
    ? `${selectedService.name} | ${categoryName} Services`
    : subcategoryName 
      ? `${subcategoryName} in ${categoryName} | Service Marketplace` 
      : `${categoryName} Services | Service Marketplace`;
  
  // Dynamic description based on what's selected
  const description = selectedService
    ? `Book professional ${selectedService.name} service starting at ₹${selectedService.lowestPrice}. Verified providers with quality guarantee.`
    : selectedSubcategory
      ? `Find the best ${subcategoryName.toLowerCase()} services in ${categoryName.toLowerCase()} category. Professional services with competitive pricing.`
      : `Find the best ${categoryName.toLowerCase()} services from verified professionals. All services come with quality guarantee and competitive pricing.`;
  
  // Dynamic image path - replace with your actual image paths
  const imagePath = selectedService
    ? `/images/services/${selectedService.id}.jpg`
    : selectedSubcategory
      ? `/images/subcategories/${selectedSubcategory.id}.jpg`
      : `/images/categories/${category?.id || 'default'}.jpg`;
  
  // Dynamic keywords
  const keywords = `${categoryName}, ${subcategoryName}, ${selectedService?.name || ''}, services, marketplace, professional services, India`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imagePath} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imagePath} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={`${window.location.origin}/services/${category?.id || ''}`} />
    </Helmet>
  );
};

export default ServicePageMeta;
