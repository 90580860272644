// src/components/Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-green-900 to-green-800 text-white">
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-10">
        {/* Logo and Description */}
        <div className="flex flex-col md:flex-row justify-between mb-8">
          <div className="mb-8 md:mb-0 md:w-1/3">
            <h2 className="text-2xl font-bold mb-4">TribeLink</h2>
            <p className="text-gray-300 mb-4 pr-4">
              Connecting communities with trusted local service providers. Our platform makes it easy to find, book, and manage services in your neighborhood.
            </p>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-green-400" />
              <p className="text-gray-300">123 Business Avenue, Tech Park, Bangalore</p>
            </div>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-green-400" />
              <a href="mailto:contact@tribelink.co.in" className="text-gray-300 hover:text-white transition">contact@tribelink.co.in</a>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faPhone} className="mr-2 text-green-400" />
              <a href="tel:+919876543210" className="text-gray-300 hover:text-white transition">+91 98765 43210</a>
            </div>
          </div>

          {/* Quick Links Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:w-2/3">
            {/* Company Section */}
            <div>
              <h3 className="text-lg font-semibold mb-4 border-b border-green-700 pb-2">Company</h3>
              <ul className="space-y-2">
                <li><a href="/about" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">About us</a></li>
                <li><a href="/terms" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Terms & conditions</a></li>
                <li><a href="/privacy" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Privacy policy</a></li>
                <li><a href="/careers" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Careers</a></li>
              </ul>
            </div>

            {/* For Customers Section */}
            <div>
              <h3 className="text-lg font-semibold mb-4 border-b border-green-700 pb-2">For Customers</h3>
              <ul className="space-y-2">
                <li><a href="/categories" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Services near you</a></li>
                <li><a href="/how-it-works" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">How it works</a></li>
                <li><a href="/blog" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Blog & Resources</a></li>
                <li><a href="/contact" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Contact support</a></li>
              </ul>
            </div>

            {/* For Partners Section */}
            <div>
              <h3 className="text-lg font-semibold mb-4 border-b border-green-700 pb-2">For Partners</h3>
              <ul className="space-y-2">
                <li><a href="/register" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Register as a professional</a></li>
                <li><a href="/partner-benefits" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Partner benefits</a></li>
                <li><a href="/success-stories" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Success stories</a></li>
                <li><a href="/partner-resources" className="text-gray-300 hover:text-white hover:pl-1 transition-all duration-300 block">Resources</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Newsletter and Social Links */}
        <div className="border-t border-green-700 pt-8 mt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Newsletter Subscription */}
            <div className="mb-6 md:mb-0 w-full md:w-1/2">
              <h3 className="text-lg font-semibold mb-3">Subscribe to our newsletter</h3>
              <div className="flex">
                <input 
                  type="email" 
                  placeholder="Your email address" 
                  className="px-4 py-2 rounded-l-md w-full focus:outline-none text-gray-800"
                />
                <button className="bg-green-600 hover:bg-green-500 px-4 py-2 rounded-r-md transition-colors duration-300">
                  Subscribe
                </button>
              </div>
            </div>

            {/* Social Links Section */}
            <div className="w-full md:w-auto">
              <h3 className="text-lg font-semibold mb-3 text-center md:text-right">Connect with us</h3>
              <div className="flex justify-center md:justify-end space-x-4">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" 
                   className="bg-green-800 hover:bg-green-700 p-3 rounded-full transition-colors duration-300">
                  <FontAwesomeIcon icon={faFacebookF} className="text-xl" />
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" 
                   className="bg-green-800 hover:bg-green-700 p-3 rounded-full transition-colors duration-300">
                  <FontAwesomeIcon icon={faTwitter} className="text-xl" />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" 
                   className="bg-green-800 hover:bg-green-700 p-3 rounded-full transition-colors duration-300">
                  <FontAwesomeIcon icon={faInstagram} className="text-xl" />
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" 
                   className="bg-green-800 hover:bg-green-700 p-3 rounded-full transition-colors duration-300">
                  <FontAwesomeIcon icon={faLinkedinIn} className="text-xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Notice */}
      <div className="bg-green-950 py-4">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center text-sm">
          <p>© Copyright {new Date().getFullYear()} TribeLink. All rights reserved.</p>
          <div className="mt-2 md:mt-0">
            <a href="/sitemap" className="text-gray-400 hover:text-white mx-2 transition-colors duration-300">Sitemap</a>
            <span className="text-gray-600">|</span>
            <a href="/accessibility" className="text-gray-400 hover:text-white mx-2 transition-colors duration-300">Accessibility</a>
            <span className="text-gray-600">|</span>
            <a href="/cookies" className="text-gray-400 hover:text-white mx-2 transition-colors duration-300">Cookie Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
